import { Injectable, OnDestroy } from '@angular/core';
import { AuthState } from '../../core/auth/auth.reducers';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, first, map, shareReplay, take } from 'rxjs/operators';
import { AppState } from '../../core/core.store';
import { select, Store } from '@ngrx/store';
import { selectAuthState } from '../../core/auth/auth.selectors';
import { AuthService } from '@geneious/nucleus-api-client';

@Injectable({
  providedIn: 'root',
})
export class PasswordStrengthService implements OnDestroy {
  private worker: Worker;
  private workerMessage$: Subject<any> = new Subject<any>();
  ready$ = new BehaviorSubject(false);

  constructor(
    private store: Store<AppState>,
    private authService: AuthService,
  ) {
    this.worker = new Worker(new URL('./password-strength.worker.ts', import.meta.url), {
      type: 'module',
      name: 'Password Strength Service',
    });

    this.passwordPolicy().subscribe((passwordPolicy) => {
      this.worker.postMessage({
        event: 'init',
        appName: environment.appName,
        passwordPolicy,
      });
      this.ready$.next(true);
    });

    this.worker.onmessage = (message) => {
      this.workerMessage$.next(message);
    };

    // It's important that the penalized array is updated when a user logs in or out.
    this.store
      .pipe(select(selectAuthState))
      .subscribe((authState) => this.refreshPenalized(authState));
  }

  ngOnDestroy(): void {
    this.workerMessage$.complete();
  }

  refreshPenalized(authState: AuthState): void {
    this.worker.postMessage({
      event: 'refresh',
      isAuthenticated: authState.authenticated,
      user: authState.userInfo.user,
    });
  }

  /**
   * Whether or not a password is strong enough.
   *
   * @param password The password to test.
   * @returns {Promise<Boolean>} Resolves true if the password is valid.
   */
  validate(password: string): Promise<boolean> {
    this.worker.postMessage({ event: 'validate', password });

    return this.workerMessage$
      .pipe(
        filter((message) => message.data.event === 'validate'),
        map((message) => message.data.valid),
        take(1),
      )
      .toPromise();
  }

  /**
   * Full details of strength analysis.
   *
   * @param password {String} The password to score.
   * @returns {Promise<Object>} Resolves to an object with properties:
   *                     - score {Number}: between 0 and 1 representing the strength of the password.
   *                     - suggestions {String} Suggestions for the user on how to improve their password.
   */
  score(password: string): Promise<{ suggestions: string; score: number }> {
    this.worker.postMessage({ event: 'score', password });

    return this.workerMessage$
      .pipe(
        filter((message) => message.data.event === 'score'),
        map((message) => message.data.result),
        take(1),
      )
      .toPromise();
  }

  private passwordPolicy() {
    return this.authService.passwordPolicy().pipe(
      map((response) => response.data),
      first(),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }
}
