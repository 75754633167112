import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { merge, Observable, Subscription } from 'rxjs';
import { NgsReportService } from './ngs-report.service';
import { filter, map, mapTo, shareReplay, switchMap } from 'rxjs/operators';
import { hasChanged } from '../../viewer/viewer-state/viewer-state.model';
import { ViewerComponent } from '../../viewers-v2/viewers-v2.config';
import { ViewerDataService } from '../../viewers-v2/viewer-data/viewer-data.service';
import { DocumentSelectionSignature } from '../../document-selection-signature/document-selection-signature.model';
import { GenericReportModel } from '../../viewer-components/json-report-viewer/generic-report.models';
import { annotatedPluginDocumentViewerSelector } from '../../viewer-components/viewer-selectors';
import {
  ViewerDocumentData,
  ViewerDocumentSelection,
} from '../../viewer-components/viewer-document-data';
import { AsyncPipe } from '@angular/common';
import { ToolstripComponent } from '../../../shared/toolstrip/toolstrip.component';
import { ToolstripItemComponent } from '../../../shared/toolstrip/toolstrip-item/toolstrip-item.component';
import { RouterLink } from '@angular/router';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { PageMessageComponent } from '../../../shared/page-message/page-message.component';
import { LoadingComponent } from '../../../shared/loading/loading.component';
import { ColumnChartComponent } from '../../../features/graphs/column-chart/column-chart.component';
import { GraphHeatmapComponent } from '../../../features/graphs/graph-heatmap/graph-heatmap.component';
import { StackedColumnChartComponent } from '../../../features/graphs/stacked-column-chart/stacked-column-chart.component';
import { GraphBoxplotComponent } from '../../../features/graphs/graph-boxplot/graph-boxplot.component';
import { NgsDeIndexedTablesAlertComponent } from '../ngs-de-indexed-tables-alert/ngs-de-indexed-tables-alert.component';

@ViewerComponent({
  key: 'ngs-report-viewer',
  title: 'Pipeline Report',
  selector: annotatedPluginDocumentViewerSelector(
    [
      DocumentSelectionSignature.forDocumentClass(
        'com.biomatters.plugins.nextgenBiologics.AntibodyAnnotatorDocument',
        1,
        1,
      ),
    ],
    (data) => {
      const document = data.selection.rows[0];
      const isPeptideOrProteinResult =
        document.getAnnotatorCreationOptions()?.sequences_chain === 'genericSequence';
      return (
        !(document.getAllFields().hideNGSPipelineReport === 'true') && !isPeptideOrProteinResult
      );
    },
  ),
})
@Component({
  selector: 'bx-ngs-report-viewer',
  templateUrl: './ngs-report-viewer.component.html',
  standalone: true,
  imports: [
    ToolstripComponent,
    ToolstripItemComponent,
    RouterLink,
    NgbTooltip,
    PageMessageComponent,
    LoadingComponent,
    ColumnChartComponent,
    GraphHeatmapComponent,
    StackedColumnChartComponent,
    GraphBoxplotComponent,
    AsyncPipe,
    NgsDeIndexedTablesAlertComponent,
  ],
})
export class NgsReportViewerComponent implements OnInit, OnDestroy {
  @HostBinding('class') readonly hostClass =
    'd-flex flex-column flex-grow-1 flex-shrink-1 overflow-auto';
  currentReport$: Observable<GenericReportModel | null>;
  isLoading$: Observable<boolean>;

  DEFAULT_DISPLAY_MSG = 'Please select an NGS report document.';
  errorMessage$: Observable<string>;

  private state$: Observable<ViewerDocumentSelection>;
  private subscriptions: Subscription = new Subscription();
  private openedAt: number;

  constructor(
    private ngsReportService: NgsReportService,
    private viewerDataService: ViewerDataService<ViewerDocumentData>,
  ) {
    const data$ = this.viewerDataService.getData('ngs-report-viewer');
    this.state$ = data$.pipe(map((data) => data.selection));
  }

  ngOnInit() {
    this.openedAt = Date.now();

    let lastId: string;

    // Valid state ensures that we don't reload the viewer when documents come/go in the files table.
    const validState$ = this.state$.pipe(
      filter((state) => state && state.rows.length > 0),
      filter((state) => hasChanged(lastId, state.rows[0].id, () => (lastId = state.rows[0].id))),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );

    const selectedReport$ = validState$.pipe(
      switchMap((state) => this.ngsReportService.getSelectedReport(state)),
    );

    this.currentReport$ = merge(validState$.pipe(mapTo(null)), selectedReport$);

    this.errorMessage$ = merge(
      validState$.pipe(map(() => '')),
      selectedReport$.pipe(map((report) => (report ? '' : this.DEFAULT_DISPLAY_MSG))),
    );

    this.isLoading$ = merge(
      validState$.pipe(map(() => true)),
      selectedReport$.pipe(map(() => false)),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
