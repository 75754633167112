@if ((indexingRequiredTables$ | async) > 0) {
  <ngb-alert type="info" [dismissible]="false" class="m-1 p-1">
    <span class="d-flex">
      <fa-icon [icon]="icon" class="m-2" data-spec="insight-icon"></fa-icon>
      <p class="d-flex m-2" [ngbTooltip]="toolTip$ | async" placement="bottom-left">
        {{ alertMessage$ | async }}
      </p></span
    >
  </ngb-alert>
}
