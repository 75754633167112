@import 'src/biologics-material-theme/accordions';

bx-accordion-step {
  counter-increment: step-counter;

  .errors {
    .error {
      color: red;
      font-size: 14px;
      margin-left: $accordion-padding-left;
      margin-bottom: 5px;
    }
  }

  .header {
    // Override mat.
    width: 100%;
    text-align: left;
    padding-top: 5px;

    &:before,
    .title {
      display: inline;
      vertical-align: middle;
    }

    &:before {
      background-color: #03a9f4;
      content: counter(step-counter);
      border-radius: 50%;
      height: 30px;
      width: 30px;
      line-height: 30px;
      display: inline-block;
      text-align: center;
      vertical-align: middle;
      box-sizing: border-box;
      color: white;
      font-weight: bold;

      margin-right: 10px;
    }
  }

  .body {
    max-height: 0;
    overflow: hidden;
  }
  .body.expanded {
    max-height: unset;
  }

  button {
    border: none;
    background: none;
  }

  // Continue buttons.
  .continue {
    color: white;
    background: #03a9f4;
    margin-left: $accordion-padding-left;
    margin-bottom: 10px;
  }
}

bx-accordion-step .header[disabled]:before {
  background-color: rgba(0, 0, 0, 0.33);
}
