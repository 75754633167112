@if (currentReport$ | async; as report) {
  <bx-ngs-de-indexed-tables-alert [documentID]="report.documentID"></bx-ngs-de-indexed-tables-alert>
  <bx-toolstrip>
    <bx-toolstrip-item>
      @if (report?.numberInputSequences <= 10000) {
        <a
          class="btn btn-light rounded"
          target="_blank"
          routerLink="/report"
          [queryParams]="{ id: report.documentID }"
        >
          Export to PDF
        </a>
      } @else {
        @if (report?.numberInputSequences > 10000) {
          <div
            ngbTooltip="Export to PDF only supported for results with 10,000 sequences or less."
            placement="bottom"
            container="body"
          >
            <button disabled="true" class="btn btn-light disabled">Export to PDF</button>
          </div>
        }
      }
    </bx-toolstrip-item>
  </bx-toolstrip>
}
<div class="d-flex flex-column overflow-auto p-3 flex-grow-1 flex-shrink-1">
  @if (errorMessage$ | async; as error) {
    <bx-page-message>
      <span>{{ error }}</span>
    </bx-page-message>
  }
  @if (isLoading$ | async) {
    <bx-page-message>
      <bx-loading></bx-loading>
    </bx-page-message>
  }
  @if (currentReport$ | async; as report) {
    <div>
      @if (report.textAtBeginningOfReport) {
        <div style="white-space: pre-line">
          {{ report.textAtBeginningOfReport }}
        </div>
      }
      <div>
        @for (param of report.params; track param) {
          <div>
            {{ param.label }}:
            @if (!param.link) {
              <span>{{ param.value }}</span>
            }
            @if (param.link) {
              <a routerLink="{{ param.link }}">{{ param.value }}</a>
            }
          </div>
        }
      </div>
      @if (report.warnings.length) {
        <ul>
          Job Warnings:
          @for (warning of report.warnings; track warning) {
            <li>
              {{ warning }}
            </li>
          }
        </ul>
      }
      @for (chart of report.barCharts; track chart) {
        <div>
          <bx-column-chart
            class="h-static-2 overflow-y-auto overflow-hidden mb-4"
            [data]="chart.data"
            [title]="chart.title"
            [xAxisTitle]="chart.xLabel"
            [yAxisTitle]="chart.yLabel"
          >
          </bx-column-chart>
        </div>
      }
      @for (heatmap of report.heatmaps; track heatmap) {
        <div>
          <bx-graph-heatmap
            class="mb-4"
            [data]="heatmap.data"
            [xAxisTitle]="heatmap.xAxisTitle"
            [yAxisTitle]="heatmap.yAxisTitle"
            [title]="heatmap.title"
          >
          </bx-graph-heatmap>
        </div>
      }
      @for (stacked of report.stackedBarCharts; track stacked) {
        <div>
          <bx-stacked-column-chart
            class="h-static-3 overflow-y-auto overflow-hidden mb-4"
            [data]="stacked.data"
            [title]="stacked.title"
            [stacking]="'normal'"
            xAxisTitle="Gene family"
            yAxisTitle="Frequency %"
          >
          </bx-stacked-column-chart>
        </div>
      }
      @for (boxplot of report.boxPlots; track boxplot) {
        <div>
          <bx-graph-boxplot
            class="h-static-2 overflow-y-auto overflow-hidden mb-4"
            [data]="boxplot.data"
            [type]="'mutation-distribution-plot'"
            [title]="boxplot.title"
            [xAxisTitle]="'Gene'"
            [yAxisTitle]="'Percent somatic hypermutation'"
          >
          </bx-graph-boxplot>
        </div>
      }
    </div>
  }
</div>
