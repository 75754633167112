import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit } from '@angular/core';
import { distinctUntilChanged, filter, map, switchMap, take, withLatestFrom } from 'rxjs/operators';
import {
  DocumentTableUIIndexState,
  IndexStateEnum,
} from '../../document-table-service/document-table-state/document-table-state';
import { combineLatest, Observable, retry, Subject, timer } from 'rxjs';
import { selectOrganizationID, selectUserID } from '../../auth/auth.selectors';
import { ReIndexingEventsService } from '../../document-table-service/document-table-state/re-indexing-events.service';
import { JobService } from '@geneious/nucleus-api-client';
import { Store } from '@ngrx/store';
import { AppState } from '../../core.store';
import { AsyncPipe, NgIf } from '@angular/common';

@Component({
  selector: 'bx-ngs-cancel-reindexing',
  templateUrl: './ngs-cancel-reindexing.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AsyncPipe, NgIf],
})
export class NgsCancelReindexingComponent implements OnInit, OnChanges {
  @Input() uiIndexState: DocumentTableUIIndexState;
  protected readonly IndexStateEnum = IndexStateEnum;
  uiIndexState$ = new Subject<DocumentTableUIIndexState>();
  jobCancellable$: Observable<boolean>;

  constructor(
    private readonly reIndexingEventsService: ReIndexingEventsService,
    private readonly jobService: JobService,
    private readonly store: Store<AppState>,
  ) {}

  ngOnChanges() {
    this.uiIndexState$.next(this.uiIndexState);
  }

  ngOnInit() {
    const indexingJobID$ = this.uiIndexState$.pipe(
      filter(
        (state) => state.indexingJobID && state.currentIndexState === IndexStateEnum.REINDEXING,
      ),
      distinctUntilChanged(
        (previous, current) =>
          previous.currentIndexState === current.currentIndexState &&
          previous.indexingJobID === current.indexingJobID,
      ),
      map((state) => state.indexingJobID),
      filter((jobId) => jobId !== undefined),
    );
    const isSubmittedByCurrentUser$ = combineLatest([
      this.store.select(selectOrganizationID).pipe(take(1)),
      indexingJobID$,
    ]).pipe(
      switchMap(([orgID, jobID]) => this.getSubmitterID(orgID, jobID)),
      withLatestFrom(this.store.select(selectUserID).pipe(take(1))),
      map(([submitterID, currentUserID]) => submitterID === currentUserID),
    );
    const isReIndexing$ = this.uiIndexState$.pipe(
      distinctUntilChanged((prev, curr) => prev.currentIndexState === curr.currentIndexState),
      map((state) => state.currentIndexState === IndexStateEnum.REINDEXING),
    );
    this.jobCancellable$ = combineLatest([isSubmittedByCurrentUser$, isReIndexing$]).pipe(
      map(([isSubmittedByCurrentUser, isReIndexing]) => isSubmittedByCurrentUser && isReIndexing),
    );
  }

  cancelReIndexing() {
    this.reIndexingEventsService.cancelReIndexing(this.uiIndexState.indexingJobID);
  }

  /**
   * Returns the submitterID observable for given input.
   * Retrying is important as sometimes UI beats Job service to process the job events and therefore nucleus may return 403 response,
   * if UI query the information before they available in Job Service
   */
  private getSubmitterID(orgID: any, jobID: any) {
    return this.jobService.getOrganizationJob(orgID, jobID).pipe(
      retry({
        count: 3,
        delay: (_, attempt) => timer(2000 * attempt),
      }),
      map((job) => job.data.submitter.userID),
    );
  }
}
