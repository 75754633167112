import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  documentTableSelected,
  fetchDocumentTables,
  fetchDocumentTablesFailure,
  fetchDocumentTablesInBulk,
  fetchDocumentTablesSuccess,
  listenToReIndexingDocumentTableEvents,
  listenToReIndexingJobProgress,
  refreshTables,
  reIndexDocumentTableFailure,
  reIndexDocumentTableProgressUpdate,
  reIndexDocumentTables,
  reIndexDocumentTableSuccess,
  restoreDocumentTable,
  restoreDocumentTableFailure,
  restoreDocumentTableProgressUpdate,
  restoreDocumentTableSuccess,
} from './document-table-state.actions';
import {
  catchError,
  distinctUntilChanged,
  exhaustMap,
  filter,
  groupBy,
  map,
  mergeAll,
  mergeMap,
  switchMap,
  take,
  takeWhile,
  timeout,
} from 'rxjs/operators';
import { DocumentService } from '../../../../nucleus/services/documentService/document-service.v1';
import { FolderService } from '../../folders/folder.service';
import {
  concatMap,
  GroupedObservable,
  interval,
  merge,
  Observable,
  of as observableOf,
  of,
  startWith,
  Subscriber,
  zip,
} from 'rxjs';
import { Action, select, Store } from '@ngrx/store';
import { DocumentTableStore } from './document-table-state';
import {
  restoreRequiredTableSelector,
  selectDocumentTable,
  selectDocumentTableSequencesCount,
} from './document-table-state.selectors';
import {
  isAllSequencesTable,
  isChainCombinationsTable,
  isComparisonSummaryTable,
} from '../../ngs/table-type-filters';
import { sortByNaturalHumanAntibody } from './regions-sort';
import { ReIndexingEventsService } from './re-indexing-events.service';
import { DocumentActivityEventKind } from '../../../../nucleus/v2/models/activity-events/activity-event-kind.model';
import {
  DocumentStatusKind,
  DocumentTableIndexCompletedEvent,
  DocumentTableReIndexInitiatedEvent,
} from '../../../../nucleus/v2/models/activity-events/document-activity-event.model';

@Injectable()
export class DocumentTableStateEffects {
  fetchDocumentTablesOnDocumentSelection$ = createEffect(() =>
    this.folderService.folderSelectionState$.pipe(
      filter((state) => state?.totalSelected === 1),
      map((selection) => selection.rows[0]),
      filter(
        (document) =>
          document.type === 'ngsResult' ||
          document.type === 'ngsComparison' ||
          document.type === 'masterDatabase',
      ),
      map((document) =>
        fetchDocumentTables({
          documentID: document.id,
          numberOfSequences: document.number_of_sequences,
        }),
      ),
    ),
  );

  fetchDocumentTablesInBulk$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchDocumentTablesInBulk),
      map(({ documentIDs }) => documentIDs),
      mergeAll(),
      mergeMap((documentID) => this.getTablesForDocument(documentID), 10),
    ),
  );

  fetchDocumentTables$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchDocumentTables, refreshTables),
      switchMap(({ documentID }) => this.getTablesForDocument(documentID)),
    ),
  );

  listenToReIndexingJobProgress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(listenToReIndexingJobProgress),
      mergeMap(({ documentID, tableNames, indexingJobID }) =>
        this.listenToReIndexingJobProgress(documentID, tableNames, indexingJobID),
      ),
    ),
  );

  listenToReIndexingDocumentEvents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(listenToReIndexingDocumentTableEvents),
      switchMap(({ documentID, tableName }) =>
        this.listenToReIndexingDocumentEvents(documentID, tableName),
      ),
    ),
  );

  /**
   * Start listening to document events, if indexState is absent. This step is important for automatic reloading of the table data when the re-indexing is completed
   */
  startListeningToDocumentEventsOnDocumentSelection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(documentTableSelected),
      distinctUntilChanged((prev, curr) => prev.tableName === curr.tableName),
      switchMap(({ documentID, tableName }) => {
        return this.store.pipe(
          select(selectDocumentTable(documentID, tableName)),
          filter((table) => !!table),
          take(1),
          map((table) => table.indexState),
          filter((indexState) => indexState === 'absent'),
          map((_) => listenToReIndexingDocumentTableEvents({ documentID, tableName })),
        );
      }),
    ),
  );
  /**
   * if indexState is absent and DocumentStatusKind start listening to job progress events, so that the indexing progress is shown in UI.
   */
  startListeningToJobProgressOnDocumentSelection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(documentTableSelected),
      distinctUntilChanged((prev, curr) => prev.tableName === curr.tableName),
      switchMap(({ documentID, tableName }) => {
        return this.store.pipe(
          select(selectDocumentTable(documentID, tableName)),
          filter((table) => !!table),
          take(1),
          filter(
            (table) =>
              table.status.kind &&
              table.status.kind === DocumentStatusKind.INDEXING &&
              table.indexState === 'absent',
          ),
          map((table) =>
            listenToReIndexingJobProgress({
              documentID,
              tableNames: [tableName],
              indexingJobID: table.status.jobID,
            }),
          ),
        );
      }),
    ),
  );

  restoreIfArchived$ = createEffect(() =>
    this.actions$.pipe(
      ofType(documentTableSelected),
      mergeMap(({ documentID, tableName }) => {
        return this.store.pipe(
          select(restoreRequiredTableSelector(documentID, tableName)),
          filter((table) => !!table),
          take(1),
          map((table) => table.indexState),
          //absent state needed to be handled separately from now on.
          filter((indexState) => indexState === 'archived'),
          switchMap((_) => {
            return of(restoreDocumentTable({ documentID, tableName }));
          }),
        );
      }),
    ),
  );

  restoreDocumentTable$ = createEffect(() =>
    this.actions$.pipe(
      ofType(restoreDocumentTable),
      groupBy((action) => action.documentID + action.tableName),
      mergeMap((group$) => this.restoreDocumentTable(group$), this.RESTORE_CONCURRENCY_LIMIT),
    ),
  );

  reIndexDocumentTables$ = createEffect(() =>
    this.actions$.pipe(
      ofType(reIndexDocumentTables),
      mergeMap(
        (action) => this.startReIndexing(action.documentID, action.tableNames),
        this.RESTORE_CONCURRENCY_LIMIT,
      ),
    ),
  );

  /**
   * Now both UI and DTS wait until TABLE_INDEX_COMPLETED event and sometimes UI beats DTS in processing the event, this causes nucleus to throw 400 when UI try to
   *  retrieve the table content. Therefore, UI should wait until DTS process the event and put the indexState back to open.
   *  Therefore, this method checks the indexState each 2 seconds until table states become open or it timeouts(should not happen theoretically)
   */
  private checkAndWaitForOpenIndexState(documentID: string, tableName: string) {
    return interval(2000).pipe(
      concatMap(() => this.documentService.getTable(documentID, tableName)),
      takeWhile((response) => response.indexState == 'absent', true), // Continue polling until response is not `absent`
      filter((a) => a.indexState == 'open'), // only emit if the index state is open
      map(() => tableName),
      timeout(30000), // Timeout if exceeded
    );
  }

  private listenToReIndexingJobProgress(
    documentID: string,
    tableNames: string[],
    jobID: string,
  ): Observable<Action> {
    return this.reIndexingEventsService.listenReIndexingJobProgress(jobID).pipe(
      startWith(0),
      map((progress) =>
        reIndexDocumentTableProgressUpdate({
          documentID,
          tableNames: tableNames,
          progress: progress,
          indexingJobID: jobID,
        }),
      ),
    );
  }

  /**
   * Start listening for RE_INDEX_INITIATED and TABLE_INDEX_COMPLETED, while emitting reIndexDocumentTableProgressUpdate, reIndexDocumentTableSuccess or reIndexDocumentTableFailure
   * @param documentID
   * @param tableName
   * @private
   */
  private listenToReIndexingDocumentEvents(
    documentID: string,
    tableName: string,
  ): Observable<Action> {
    const onReIndexingError = () =>
      observableOf(
        reIndexDocumentTableFailure({
          documentID,
          tableNames: [tableName],
          error: 'Failed to restore document table',
        }),
      );

    const completedEvents$ = this.reIndexingEventsService
      .listenReIndexingDocumentActivity(documentID, tableName)
      .pipe(
        filter((event) => event.event.kind === DocumentActivityEventKind.TABLE_INDEX_COMPLETED),
        concatMap((event) =>
          this.checkAndWaitForOpenIndexState(
            documentID,
            (event.event as DocumentTableIndexCompletedEvent).tableName,
          ),
        ),
        map((table) =>
          reIndexDocumentTableSuccess({
            documentID,
            tableNames: [table],
          }),
        ),
        catchError(onReIndexingError),
      );

    const initiatedEvent$ = this.reIndexingEventsService
      .listenReIndexingDocumentActivity(documentID, tableName)
      .pipe(
        filter((event) => event.event.kind === DocumentActivityEventKind.RE_INDEX_INITIATED),
        map((event) => {
          const indexingTables = (event.event as unknown as DocumentTableReIndexInitiatedEvent)
            .tables;
          const tableNames = Object.keys(indexingTables);
          return reIndexDocumentTableProgressUpdate({
            documentID,
            tableNames,
            progress: 0,
            indexingJobID: indexingTables[tableNames[0]].jobID,
          });
        }),
        catchError(onReIndexingError),
      );
    return merge(completedEvents$, initiatedEvent$);
  }

  private startReIndexing(documentID: string, tableNames: string[]): Observable<Action> {
    return this.reIndexingEventsService.startReIndexing(documentID, ...tableNames).pipe(
      switchMap((response) => {
        if (response.data.createdJobID) {
          return new Observable<Action>((observer) => {
            observer.next(
              listenToReIndexingJobProgress({
                documentID,
                tableNames: tableNames,
                indexingJobID: response.data.createdJobID,
              }),
            );
          });
        } else {
          return new Observable<Action>((observer) => sendFailure(observer));
        }
      }),
    );

    function sendFailure(observer: Subscriber<Action>) {
      observer.next(
        reIndexDocumentTableFailure({
          documentID,
          tableNames: tableNames,
          error: 'Failed to restore document table',
        }),
      );
    }
  }

  // Limit the number of restore requests at a time that can be made to the Nucleus Document Table Service by a user session.
  private RESTORE_CONCURRENCY_LIMIT = 2;

  constructor(
    private readonly actions$: Actions,
    private readonly documentService: DocumentService,
    private readonly folderService: FolderService,
    private readonly store: Store<DocumentTableStore>,
    private readonly reIndexingEventsService: ReIndexingEventsService,
  ) {}

  private getTablesForDocument(documentID: string) {
    return this.documentService.getTables(documentID).pipe(
      map(sortByNaturalHumanAntibody),
      map((tables) => fetchDocumentTablesSuccess({ documentID, tables })),
      catchError((error) => {
        if (error.status === 403) {
          return of(
            fetchDocumentTablesFailure({
              documentID,
              reason: 'No tables exist on this document',
            }),
          );
        } else {
          return of(
            fetchDocumentTablesFailure({
              documentID,
              reason: 'Failed to fetch document tables',
            }),
          );
        }
      }),
    );
  }

  private restoreDocumentTable(
    group$: GroupedObservable<string, ReturnType<typeof restoreDocumentTable>>,
  ) {
    return group$.pipe(
      exhaustMap(({ documentID, tableName }) => {
        const numberOfSequences$ = this.store.pipe(
          select(selectDocumentTableSequencesCount(documentID)),
          take(1),
        );
        const table$ = this.store.pipe(select(selectDocumentTable(documentID, tableName)), take(1));
        const restoreTableCompleted$ = this.documentService.restoreTable(documentID, tableName);

        return zip(table$, numberOfSequences$).pipe(
          switchMap(([table, numberOfSequences]) => {
            return new Observable<Action>((observer) => {
              const longTableEquation = 2 * 10 ** -5 * (numberOfSequences ?? 100000) + 10;
              const shortTableEquation = 8 * 10 ** -7 * (numberOfSequences ?? 100000) + 5;
              const isLargeTable =
                isAllSequencesTable(table) ||
                isChainCombinationsTable(table) ||
                isComparisonSummaryTable(table);
              const restoreDurationEstimateSeconds = isLargeTable
                ? longTableEquation
                : shortTableEquation;
              const progressTime = 100 / restoreDurationEstimateSeconds;
              let progress = 0;
              let totalTime = 0;

              restoreTableCompleted$.subscribe({
                next: () => {
                  observer.next(restoreDocumentTableSuccess({ documentID, tableName }));
                  observer.complete();
                },
                error: () => {
                  observer.next(
                    restoreDocumentTableFailure({
                      documentID,
                      tableName,
                      error: 'Failed to restore document table',
                    }),
                  );
                },
              });

              const intervalListener = setInterval(() => {
                progress += progressTime;
                totalTime += 1000;
                observer.next(
                  restoreDocumentTableProgressUpdate({
                    documentID,
                    tableName,
                    progress: Math.min(100, progress),
                  }),
                );

                if (progress >= 100) {
                  clearInterval(intervalListener);
                }
              }, 1000);

              return () => {
                clearInterval(intervalListener);
              };
            }).pipe(
              // This theoretically should never happen, but it's possible.
              catchError(() =>
                of(
                  restoreDocumentTableFailure({
                    documentID,
                    tableName,
                    error: 'Failed to restore document table',
                  }),
                ),
              ),
            );
          }),
        );
      }),
    );
  }
}
