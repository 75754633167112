@if (
  {
    isLoading: isLoading$ | async,
    sequences: sequences$ | async,
    messages: messages$ | async,
    showSequenceViewer: showSequenceViewer$ | async,
    preferences: sequenceViewerPreferencesState$ | async,
    previewTableArchived: isPreviewNotAvailable$ | async,
    tablesLoaded: tablesLoaded$ | async,
    tablesLoadingError: tablesLoadingError$ | async,
    openQueryParams: openQueryParams$ | async,
  };
  as state
) {
  @if (state.tablesLoaded) {
    @if (state.previewTableArchived) {
      <bx-ngs-document-restore-screen
        [documentID]="documentID$ | async"
        [tableName]="DEFAULT_TABLE"
      >
      </bx-ngs-document-restore-screen>
    } @else {
      <bx-ngs-sequence-preview-info-box
        [totalNumberOfSequences]="(state.sequences ?? []).length"
        [maximumNumberOfSequences]="PREVIEW_ROW_LIMIT"
        [openQueryParams]="state.openQueryParams"
      ></bx-ngs-sequence-preview-info-box>
      @if (state.isLoading === false && state.messages) {
        <bx-page-message class="messages">
          <span>{{ state.messages }}</span>
        </bx-page-message>
      }
      @if (state.isLoading && !state.messages) {
        <div class="flex-grow-1 d-flex justify-content-center align-items-center">
          <bx-loading></bx-loading>
        </div>
      }
      @if (!state.isLoading && state.showSequenceViewer && !state.messages) {
        <bx-sequence-viewer
          class="w-100 flex-grow-1 flex-shrink-1 overflow-hidden"
          [metadataColumns]="metadataColumns$ | async"
          [metadataColumnOrder]="columnIDs$ | async"
          [sequences]="state.sequences"
          [documentsType]="(isAlignment$ | async) ? 'Alignment' : 'NGS'"
          [sortingEnabled]="gotAllSequences$ | async"
          [savedOptions]="state.preferences"
          [toolbarWarningMsg]="toolbarWarningMsg$ | async"
          (optionChanged)="optionChanged($event)"
          (optionRemoved)="optionRemoved($event)"
          (liabilityThresholdChanged)="liabilityScoreThresholdChanged($event)"
        >
        </bx-sequence-viewer>
      }
    }
  } @else {
    @if (state.tablesLoadingError) {
      @switch (state.tablesLoadingError) {
        @default {
          <bx-page-message>
            <h5>Failed to fetch results</h5>
            <br />
            <span>
              Close and try again later. If you continue to have problems, please
              <a href="https://help.geneiousbiologics.com">contact support.</a>
            </span>
          </bx-page-message>
        }
      }
    } @else {
      <div class="flex-grow-1 d-flex justify-content-center align-items-center">
        <bx-loading></bx-loading>
      </div>
    }
  }
}
