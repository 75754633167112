import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { DocumentTableStateService } from '../../document-table-service/document-table-state/document-table-state.service';
import { map, shareReplay } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { NgbAlert, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'bx-ngs-de-indexed-tables-alert',
  templateUrl: './ngs-de-indexed-tables-alert.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FaIconComponent, NgbTooltip, AsyncPipe, NgbAlert],
})
export class NgsDeIndexedTablesAlertComponent implements OnInit {
  @Input() documentID!: string | null;
  indexingRequiredTables$: Observable<number>;
  alertMessage$: Observable<string>;
  toolTip$: Observable<string>;
  protected readonly icon = faInfoCircle;

  constructor(private readonly tableStateService: DocumentTableStateService) {}

  ngOnInit(): void {
    const deIndexedTables$ = this.tableStateService
      .getTablesWithNoOpenIndex(this.documentID)
      .pipe(shareReplay({ refCount: true, bufferSize: 1 }));
    this.indexingRequiredTables$ = deIndexedTables$.pipe(
      map((tables) => tables.length),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );
    this.alertMessage$ = deIndexedTables$.pipe(
      map((tables) => {
        const prefix = tables.length == 1 ? '1 table has ' : tables.length + ' tables have ';
        return `${prefix} been deep archived due to inactivity.`;
      }),
    );
    this.toolTip$ = deIndexedTables$.pipe(
      map(
        (tables) =>
          tables.map((table) => table.displayName).join(', ') +
          ' have been deep archived. Please navigate any of those table to restore them',
      ),
    );
  }
}
